declare var i18n: {
    [key: string]: string;
}

interface Window {
    _: (key: string) => string;
}

// INTERNATIONALIZATION
window._ = (key) => {
    if (typeof (i18n) != 'undefined' && i18n[key]) {
        return i18n[key];
    }
    throw(key + " is undefined!");
}
